import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <img src={logo} alt="logo" style={{ maxWidth: '400px', margin: '0 auto' }} />
      <p className="coming-soon" data-text="2024">MMXXIV</p>
    </div>
  );
}

export default App;
